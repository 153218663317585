import axios from 'axios';
import {Message} from 'element-ui';
import store from '@/store'

const http = axios.create({
    // 设置超时时间
    timeout: 10000,
    baseURL: process.env.NODE_ENV === "development" ? '/api' : '/',
    // 其他axios的配置详见：https://www.kancloud.cn/yunye/axios/234845

});

// 添加请求拦截器
http.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `Bearer ${window.sessionStorage.getItem('access_token')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//响应拦截器
http.interceptors.response.use(
    response => {
        store.state.loading = false;
        const res = response.data
        return res
    },
    error => {
        //error.response.status状态码status
        if (error.response.status == 420) {
            window.location.href = window.location.origin;
        }
        Message({
            message: error.response.data.message,
            type: 'error',
            duration: 3 * 1000
        })
        return Promise.reject(error);
    }
);

export default http;
