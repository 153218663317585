const layout = {
    //当namespaced=true时vuex,将会自动给各自module添加访问路径名。方便区分moduel
    namespaced: true,
    state: {
    },
    mutations: {},
    actions: {
    },
    getters: {},
}
export default layout
