import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//主css
import '@/assets/css/index.scss';

//svg文件夹
import '@/assets/icons'

//加载路由守卫
import '@/assets/utils/routerGuard'

//公用类
import Utils from "@/assets/utils/utils";
Vue.prototype.Utils = Utils;

Vue.use(ElementUI, {
    size: 'small'
});

//阻止显示生产模式的消息
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
