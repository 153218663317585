//根据项目来定，默认传入的是10位时间戳  默认是1
function timeStamp13(timeStamp, num = 1) {
    let stamp
    switch (num) {
        case 1:
            stamp = timeStamp * 1000;//这个是把十位的时间戳转换成13位时间戳
            break
        case 2:
            stamp = timeStamp;//这个可以根据该默认值来执行
    }
    return new Date(stamp);
}

//处理完后输出
function process(params) {
    return params < 10 ? ('0' + params) : params;
}

//月 小于10，显示的时候前面加个0   num 1:月 2:日 3:时 4:分 5:秒
function dateFormat(date, num) {
    switch (num) {
        case 1:
            return process(date.getMonth() + 1);
        case 2:
            return  process(date.getDate());
        case 3:
            return  process(date.getHours());
        case 4:
            return  process( date.getMinutes());
        case 5:
            return process(date.getSeconds());
    }
}


//时间戳位数：1619751776  获得单前时间时间戳：Math.round(new Date() / 1000)
const utils = {
    // 时间戳转换成时间，只转化到日期没有到时间和秒 2017-08-25
    timeDate(timeStamp) {
        let date = timeStamp13(timeStamp);
        let year = date.getFullYear();
        let month = dateFormat(date, 1);
        let day = dateFormat(date, 2);
        return year + '-' + month + '-' + day;
    },

    // 时间戳转换成日期只到秒  2021-04-01 12:36:16
    timeDateSeconds(timeStamp) {
        let date = timeStamp13(timeStamp);
        let year = date.getFullYear();
        let month = dateFormat(date, 1);
        let day = dateFormat(date, 2);
        let hours = dateFormat(date, 3);
        let minutes=dateFormat(date, 4);
        let seconds =dateFormat(date, 5);
        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    },

    //日期转换成时间戳 2015-05-10 14:25:55 转成10位数时间戳
    stampData(time){
        let date= new Date(time.replace(/-/g, '/'));
        return  date.getTime() / 1000;
    },
    /*过去当前日期和过去的日期*/
    // getDay(0) 当前日期
    // getDay(-7) 前七天
    // 返回 2021-05-13
    getDay(num) {
        let date = new Date();
        let targetDayMilliseconds = date.getTime() + 1000 * 60 * 60 * 24 * num; // 将目标日转换成毫秒
        date.setTime(targetDayMilliseconds);
        let year = date.getFullYear();
        let month = dateFormat(date, 1);
        let day = dateFormat(date, 2);
        return year + "-" + month + "-" + day;
    },

    // 返回 2021-05-13 10:49:48
    getDaySecond(num) {
        let date = new Date();
        let targetDayMilliseconds = date.getTime() + 1000 * 60 * 60 * 24 * num;
        date.setTime(targetDayMilliseconds);
        let year = date.getFullYear();
        let month = dateFormat(date, 1);
        let day = dateFormat(date, 2);
        let hours = dateFormat(date, 3);
        let minutes=dateFormat(date, 4);
        let seconds =dateFormat(date, 5);
        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }
}

module.exports = utils
