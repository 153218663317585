<template>
  <div>
    <div class="main" style="margin-bottom: 25px">
      <el-scrollbar wrap-class="scrollbar-x">
        <div class="main-left">
          <layout-left/>
        </div>
      </el-scrollbar>
      <div class="main-right">
        <div class="main-right-head" style="width:calc(100% - 200px)">
          <layout-head/>
        </div>
        <div class="main-right-content">
          <router-view/>
        </div>
      </div>
    </div>
    <div style="position: fixed; bottom: 0; line-height: 25px; background: #1d1d1f; text-align: center; width: 100%; font-size: 12px; color: #d2d2d7">
      <el-link href="https://beian.miit.gov.cn" target="_blank" style="color: #d2d2d7; font-size: 12px" type="info">闽ICP备16025860号-2</el-link>
      华创信息科技有限公司
    </div>
  </div>
</template>

<script>
import LayoutHead from "./components/LayoutHead";
import LayoutLeft from "./components/LayoutLeft";
import axios from "axios";

export default {
  components: {LayoutHead, LayoutLeft},
  props: [],
  data() {
    return {};
  },
  mounted() {
    // 属性token 半个小时
    setInterval(() => {

      if (window.sessionStorage.getItem('access_token') == null) {
        this.$message.error('请登录账号');
        window.location = window.location.origin + window.location.pathname;
      }
      const url = `${process.env.VUE_APP_URL}/api/user/tokenRefresh`
      //刷新token
      axios.get(url,
          {
            headers: {
              Authorization: `Bearer ${window.sessionStorage.getItem('access_token')}`
            },
          }
      ).then(response => {
        console.log(response)
        window.sessionStorage.setItem('access_token', response.data.data.access_token);
      })

    }, 600000);
  }
};


</script>
<style lang="scss" scoped>
.main {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  @include flex(row, nowrap, initial);

  &-left {
    overflow: auto;
  }

  &-right {
    flex: 1;
    overflow: auto;

    &-head {
      position: fixed;
      top: 0px;
      z-index: 666;
      background: #fff;
    }

    &-content {
      margin-top: 64px;
      padding: 12px 12px 70px;
      border-top: 1px solid #c3f1ec;
      min-height: calc(100vh - 150px);
      background: #e3fefb;
    }
  }
}
</style>
