// 公用接口
import url from '../url';
import http from '../http';
//登录
export function login (data) {
    return url({
        url: "/api/user/login",
        method: "post",
        data:data
    })
}
//token 刷新
export function tokenRefresh () {
    return http({
        url: "api/user/tokenRefresh",
        method: "get",
    })
}
//退出登录
export function logout (data) {
    return http({
        url: "/api/user/logout",
        method: "post",
        data:data
    })
}
//修改密码
export function editPassword (data) {
    return http({
        url: "api/user/myPassword?old_password",
        method: "post",
        data:data
    })
}





