<template>
  <div>
    <div class="layoutLeft-logo">
      <img src="@/assets/images/leftLogin.png">
    </div>
    <!--{{$store.state.newRouterArru}}-->

    <!--  :default-openeds="openeds" 默认打开-->
    <template v-if="$store.state.currentPermissions!=3">
      <el-menu class="left-menu"
               :default-active="$route.path"
               background-color="#05c69b"
               text-color="#fff"
               router
      >
        <template v-for="(item,key) in $store.state.newRouterArru">
          <div :key="key">
            <!--只有一级菜单-->
            <template v-if="item.path!=undefined">
              <el-menu-item :index="item.path">
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>

            <!--有二级或三级菜单的标题-->
            <template v-if="item.path==undefined">
              <el-submenu :index="(key).toString()">
                <template slot="title">
                  <div class="left-title">
                    <img :src="require('@/assets/images/'+item.icon+'.png')">
                    <span>{{ item.title }}</span>
                  </div>
                </template>

                <!--没有三级菜单-->
                <template v-if="item.children[0].threeMenu!=1">
                  <el-menu-item-group>
                    <div v-for="(twoItem,twoKey) in item.children" :key="twoKey">
                      <el-menu-item :index="twoItem.path">{{ twoItem.title }}</el-menu-item>
                    </div>
                  </el-menu-item-group>
                </template>

                <!--有三级菜单-->
                <template v-if="item.children[0].threeMenu==1">
                  <el-submenu :index="key.toString()+'1'">
                    <span slot="title">{{ item.children[0].title }}</span>
                    <div v-for="(threeItem,threeKey) in item.children[0].children" :key="threeKey">
                      <el-menu-item :index="threeItem.path">{{ threeItem.title }}</el-menu-item>
                    </div>
                  </el-submenu>
                </template>

              </el-submenu>
            </template>
          </div>
        </template>
      </el-menu>
    </template>


    <!--成本中心权限是否能查看到页面-->
    <!--总管理员直接显示-->
    <template v-if="$store.state.currentPermissions==3 && $store.state.routerRole.indexOf('1')!=-1">
      <el-menu class="left-menu"
               :default-active="$route.path"
               background-color="#05c69b"
               text-color="#fff"
               router
      >
        <template v-for="(item,key) in $store.state.newRouterArru">
          <div :key="key">
            <!--只有一级菜单-->
            <template v-if="item.path!=undefined">
              <el-menu-item :index="item.path">
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>
            <!--&& $store.state.routerRole.indexOf(item.id.toString)!=-1-->
            <!--有二级或三级菜单的标题-->
            <template v-if="item.path==undefined">
              <el-submenu :index="(key).toString()">
                <template slot="title">
                  <div class="left-title">
                    <img :src="require('@/assets/images/'+item.icon+'.png')">
                    <span>{{ item.title }}</span>
                  </div>
                </template>

                <!--没有三级菜单-->
                <template v-if="item.children[0].threeMenu!=1">
                  <el-menu-item-group>
                    <div v-for="(twoItem,twoKey) in item.children" :key="twoKey">
                      <el-menu-item :index="twoItem.path">{{ twoItem.title }}</el-menu-item>
                    </div>
                  </el-menu-item-group>
                </template>

                <!--有三级菜单-->
                <template v-if="item.children[0].threeMenu==1">
                  <el-submenu :index="key.toString()+'1'">
                    <span slot="title">{{ item.children[0].title }}</span>
                    <div v-for="(threeItem,threeKey) in item.children[0].children" :key="threeKey">
                      <el-menu-item :index="threeItem.path">{{ threeItem.title }}</el-menu-item>
                    </div>
                  </el-submenu>
                </template>

              </el-submenu>
            </template>
          </div>
        </template>
      </el-menu>
    </template>

    <!--不是超级管理员-->
    <template v-else>
      <el-menu class="left-menu"
               :default-active="$route.path"
               background-color="#05c69b"
               text-color="#fff"
               router
      >
        <template v-for="(item,key) in $store.state.newRouterArru">
          <div :key="key">
            <!--只有一级菜单-->
            <template v-if="item.path!=undefined  && $store.state.routerRole.indexOf(item.id.toString())!=-1">
              <el-menu-item :index="item.path">
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>
            <!--&& $store.state.routerRole.indexOf(item.id.toString)!=-1-->
            <!--有二级或三级菜单的标题-->
            <template v-if="item.path==undefined && $store.state.routerRole.indexOf(item.id.toString())!=-1">
              <el-submenu :index="(key).toString()">
                <template slot="title">
                  <div class="left-title">
                    <img :src="require('@/assets/images/'+item.icon+'.png')">
                    <span>{{ item.title }}</span>
                  </div>
                </template>

                <!--没有三级菜单-->
                <template v-if="item.children[0].threeMenu!=1">
                  <el-menu-item-group>
                    <template v-for="(twoItem,twoKey) in item.children">
                      <div  :key="twoKey" v-if="$store.state.routerRole.indexOf(twoItem.id.toString())!=-1">
                        <el-menu-item :index="twoItem.path">{{ twoItem.title }}</el-menu-item>
                      </div>
                    </template>
                  </el-menu-item-group>
                </template>

                <!--有三级菜单-->
                <template v-if="item.children[0].threeMenu==1 && $store.state.routerRole.indexOf(item.id.toString)!=-1">
                  <el-submenu :index="key.toString()+'1'">
                    <span slot="title">{{ item.children[0].title }}</span>
                    <template  v-for="(threeItem,threeKey) in item.children[0].children">
                      <div :key="threeKey" v-if="$store.state.routerRole.indexOf(threeItem.id.toString())!=-1">
                        <el-menu-item :index="threeItem.path">{{ threeItem.title }}</el-menu-item>
                      </div>
                    </template>

                  </el-submenu>
                </template>

              </el-submenu>
            </template>
          </div>
        </template>
      </el-menu>
    </template>

  </div>
</template>

<script>

export default {
  components: {},
  props: [],
  data() {
    return {
      // openeds: ['1']
    };
  },
  created() {
  },
  mounted() {
    // console.log(this.$store.state.newRouterArru)
  },
  methods: {}
};
</script>
<style lang="scss">
.left-menu {
  min-height: calc(100vh - 85px) !important;

  .el-menu-item-group__title {
    padding: 0 !important;
  }

  .el-menu-item, .el-submenu__title {
    height: 38px !important;
    line-height: 38px !important;
    font-size: 13px;
    @include flex();
  }

  //第一级的下划线
  .el-submenu__title {
    border-bottom: 1px solid #21ba9b !important;
  }

  .el-menu--inline .el-submenu__title {
    border-bottom: 0 !important;
  }

  //左边的箭头
  .el-submenu__title i {
    color: #fff;
    font-size: 18px;
  }

  //选择状态
  .is-active {
    background: #fff !important;
    color: #05c19b !important;
    font: {
      weight: bold;
    };
    margin: 5px 0 5px 40px;
    min-width: auto !important;
    padding-left: 20px !important;
    border-radius: 15px 0 0 15px;
    height: 32px !important;
    line-height: 32px !important;
  }

  //选择状态影响的class
  .is-opened, .el-submenu {
    font-size: 12px !important;
    font-weight: normal !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    height: auto !important;
    line-height: normal !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.left-menu {
  &:not(.el-menu--collapse) {
    width: 200px;
  }
}

//导航logo
.layoutLeft-logo {
  background: #12c199;
  text-align: center;
  padding: 20px 0;
  width: 200px;

  img {
    height: 55px;
  }
}

//导航title
.left-title {
  font-weight: bold;

  img {
    width: 18px;
    margin-right: 5px;
  }
}

</style>
