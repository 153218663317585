<template>
  <div>
    <div class="head-top">
      <div class="head-top-left">
        <span v-if="$store.state.currentPermissions==1">华创商贸管理系统-询单管理(采购端)</span>
        <span v-if="$store.state.currentPermissions==2">华创商贸管理系统-询单管理(销售端)</span>
        <span v-if="$store.state.currentPermissions==3">华创商贸管理系统-询单管理(成本中心)</span>
      </div>
      <div class="head-top-dropdown">

        <i class="el-icon-bel" style="font-size: 22px"></i>

       <!-- <el-badge :value="0" :max="99" class="badge">
          <img src="@/assets/images/headTip.jpg">
        </el-badge>-->
        <img :src="avatar" v-if="avatar!='null'">
        <span>{{ username }}</span>

        <el-dropdown @visible-change="dropdownShow"  @command="handleCommand">
          <i :class="{'el-icon-caret-bottom':!this.dropdownStatus,'el-icon-caret-top':this.dropdownStatus}"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="edit">修改密码</el-dropdown-item>
            <el-dropdown-item command="quit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!--addDialog 组件 -->
    <el-dialog
        :append-to-body="true"
        title="修改信息"
        :visible.sync="userDialog.isDialog"
        width="440px"
        :close-on-press-escape="false"
    >

        <ul class="user-edit">
          <li>
            <span>旧密码</span>
            <el-input v-model="userDialog.old_password" placeholder="请输入旧密码"></el-input>
          </li>
          <li>
            <span>新密码</span>
            <el-input v-model="userDialog.password" placeholder="请输入新密码"></el-input>
          </li>
          <li>
            <span>确认密码</span>
            <el-input v-model="userDialog.password_confirmation" placeholder="请输入旧密码"></el-input>
          </li>
        </ul>

      <div slot="footer" class="dialog-footer">
        <el-button @click="userDialog.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="addDialogSub">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {logout,editPassword} from '@/api/module/common'
export default {
  components: {},
  props: [],
  data() {
    return {
      username:'',//用户名称
      avatar:'',//头像
      //头像附件的下拉图标
      dropdownStatus: false,
      userDialog:{
        isDialog:false,
        old_password:'',
        password:'',
        password_confirmation:''
      }
    };
  },
  created() {
    this.username = window.sessionStorage.getItem('username');
    this.avatar = window.sessionStorage.getItem('avatar');
  },
  methods: {
    handleCommand(val){
      switch (val) {
        case 'quit':
          console.log(123)
          //登录退出
          logout().then(() => {
            this.$message.success('退出成功');
            window.location.href = window.location.origin;
          })
          break;
        case 'edit':
          //修改
            this.userDialog.isDialog=true;
          break
      }
    },
    addDialogSub(){
      let data={
        old_password:this.userDialog.old_password,
        password:this.userDialog.password,
        password_confirmation:this.userDialog.password_confirmation,
      }
      editPassword(data).then(() => {
        this.$message.success('修改成功');
        this.$router.go(0)
      })
      console.log(123)
    },
    //箭头是否下拉出来
    dropdownShow(status) {
      this.dropdownStatus = status;

    },
  }
};
</script>


<style lang="scss" scoped>
.user-edit{
  li:nth-last-of-type(1){
    margin-bottom: 0;
  }
  li{
    @include flex();
    margin-bottom: 12px;
    span{
      flex: 0 0 auto;
      width: 65px;
    }
  }

}
.head-top {
  padding: 12px;
  @include flex(row, nowrap, center, space-between);
  &-left span {
    font: {
      size: 22px;
      weight: bold;
    }
  }

  //用户账号 图片
  &-dropdown {
    @include flex();
    //铃铛提示信息
    .badge {
      margin-right: 20px;
      img{
        height: 28px;
      }
    }

    img {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }

    span {
      padding-left: 8px;
    }
  }
}
</style>
