//调用 $store.state.test
const state = {
    test: 1,
    manager:0,//是否是主管

    newRouterArru:[],//路由数组
    currentPermissions:'', //当前权限  在 window.localStorage.getItem('currentPermissions')
    routerRole:[],// 当前角色权限id 如[1,2,3]
    loading:true,
    currentButton:[], // 询单 点击提交报价等按钮 单详细后面的按钮
    currentMessage:[],// 询单信息 li后面的信息

}
export default state
