import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Layout from '@/layout'

//权限控制
// onlyOne 只有一级菜单
// towTitle 有三级列表的二级列表标题
// authority 0 不写到动态路由 1 2 3 100 全部都能显示

// 采购端：1 销售端：2 成本中心等：3
const routes = [
    {
        path: '/',
        redirect: '/login',
        meta: {authority: [0]},
    }, {
        path: '/login',
        name: 'login',
        meta: {authority: [0]},
        component: () => import(/* webpackChunkName: "login" */ '@/views/login')
    },
    {
        path: '/index',
        name: 'index',
        component: Layout,
        redirect: '/index/messageIndex',
        meta: {title: '首页', authority: [1, 2, 3], icon: '01', id: 53},
        children: [{
            path: 'messageIndex',
            name: 'messageIndex',
            meta: {title: '首页', authority: [1, 2, 3], id: 2},
            component: () => import(/* webpackChunkName: "message-index" */ '@/views/index/message-index')
        }, {
            path: 'messageCenter',
            name: 'messageCenter',
            meta: {title: '消息中心', authority: [1, 2, 3], id: 3},
            component: () => import(/* webpackChunkName: "message-center" */ '@/views/index/message-center')
        }, {
            path: 'messageNotification',
            name: 'messageNotification',
            meta: {title: '自定义消息提醒', authority: [1, 2, 3], id: 5},
            component: () => import(/* webpackChunkName: "message-notification" */ '@/views/index/message-notification')
        }]
    },
    {
        path: '/basic',
        name: 'basic',
        component: Layout,
        redirect: '/basic/roleManagement',
        meta: {title: '基础管理', authority: [3], icon: '02', id: 9},
        children: [{
            path: 'roleManagement',
            name: 'roleManagement',
            meta: {title: '角色管理', authority: [3], id: 10},
            component: () => import(/* webpackChunkName: "role-management" */ '@/views/basic/role-management')
        }, {
            path: 'accountManagement',
            name: 'accountManagement',
            meta: {title: '账号管理', authority: [3], id: 16},
            component: () => import(/* webpackChunkName: "account-management" */ '@/views/basic/account-management')
        }]
    },
    {
        path: '/staff',
        name: 'staff',
        component: Layout,
        redirect: '/staff/roleManagement',
        meta: {title: '员工管理', authority: [3], icon: '03', id: 22},
        children: [{
            path: 'salespersonManagement',
            name: 'salespersonManagement',
            meta: {title: '销售员管理', authority: [3], id: 23},
            component: () => import(/* webpackChunkName: "salesperson-Management" */ '@/views/staff/salesperson-Management')
        }, {
            path: 'purchaserManagement',
            name: 'purchaserManagement',
            meta: {title: '采购员管理', authority: [3], id: 25},
            component: () => import(/* webpackChunkName: "purchaser-Management" */ '@/views/staff/purchaser-Management')
        }]
    },
    {
        path: '/inquiry',
        name: 'inquiry',
        component: Layout,
        redirect: '/inquiry/addInquiry',
        meta: {title: '询单管理', authority: [1, 2, 3], icon: '05', id: 27},
        children: [{
            path: 'addInquiry',
            name: 'addInquiry',
            meta: {title: '添加询单', authority: [2, 3], id: 28},
            component: () => import(/* webpackChunkName: "add-inquiry" */ '@/views/inquiry/add-inquiry')
        }, {
            path: 'inquiryList',
            name: 'inquiryList',
            meta: {title: '新询单列表', authority: [1, 2, 3], id: 29},
            component: () => import(/* webpackChunkName: "inquiry-inquiry-list" */ '@/views/inquiry/inquiry-list')
        }, {
            path: 'pendingQuotationList',
            name: 'pendingQuotationList',
            meta: {title: '待报价列表', authority: [1, 3], id: 33},
            component: () => import(/* webpackChunkName: "pending-quotation-list" */ '@/views/inquiry/pending-quotation-list')
        }, {
            path: 'quotedList',
            name: 'quotedList',
            meta: {title: '已报价列表', authority: [1, 2, 3], id: 37},
            component: () => import(/* webpackChunkName: "inquiry-inquiry-list" */ '@/views/inquiry/quoted-list')
        }]
    },
    {
        path: '/orderManagement',
        name: 'orderManagement',
        component: Layout,
        redirect: '/orderManagement/index',
        meta: {title: '订单管理', authority: [1, 2, 3], icon: '01', id: 52},
        children: [
          /*  {
                path: 'table',
                name: 'table',
                meta: {title: '订单管理', authority: [3], id: 41},
                component: () => import(/!* webpackChunkName: "order-management-table" *!/ '@/views/order-management/table')
            },*/
            {
                path: 'index',
                name: 'index',
                meta: {title: '订单详细', authority: [1, 2, 3], id: 42},
                component: () => import(/* webpackChunkName: "order-management" */ '@/views/order-management')
            }]
    },
    {
        path: '/historicalQuotes',
        name: 'historicalQuotes',
        component: Layout,
        redirect: '/historicalQuotes/index',
        meta: {title: '历史报价管理', authority: [1, 2, 3], icon: '02', id: 51},
        children: [{
            path: 'index',
            name: 'index',
            meta: {title: '历史报价管理', authority: [1, 2, 3], id: 45},
            component: () => import(/* webpackChunkName: "historical-quotes" */ '@/views/historical-quotes')
        }]
    },
    {
        path: '/customer',
        name: 'customer',
        component: Layout,
        redirect: '/customer/customerManagement',
        meta: {title: '客户管理', authority: [1, 2, 3], icon: '03', id: 50},
        children: [{
            path: 'customerManagement',
            name: 'customerManagement',
            meta: {title: '客户管理', authority: [2, 3], id: 46},
            component: () => import(/* webpackChunkName: "customer-management" */ '@/views/customer/customer-management')
        }, {
            path: 'supplierManagement',
            name: 'supplierManagement',
            meta: {title: '供应商管理', authority: [1, 3], id: 48},
            component: () => import(/* webpackChunkName: "supplier-management" */ '@/views/customer/supplier-management')
        }]
    }
]

const router = new VueRouter({
    routes
})

export default router
