// 组件调用 this.$store.dispatch('消息名aa','数据') 触发store中对应的action调用
const actions = {
    // aa({
    //   commit
    // }) { 
    //   commit(bb) // 调用了 mutations里面的方法bb   
    // },
    // //带条件的action  
    // cc({  commit, state }) { //state 是state的值
    //   if (state.count % 2 === 1) {
    //     commit(bb)      
    //   }
    // }

}
export default actions