import Vue from 'vue'
import Vuex from 'vuex'
import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
import layout from './module/layout'
Vue.use(Vuex)
export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    //模块相关
    modules: {
        layout //多个用,号隔开
    },
})
