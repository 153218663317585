/*路由守卫*/
import router from '@/router'
//路由列表
import routerList from '@/assets/utils/routerList';
import state from "@/store/state";
//element 提示框
import {Message} from 'element-ui';
//在跳转之前
router.beforeEach((to, from, next) => {
    // 把权限赋值给vuex
    state.currentPermissions = Number(window.localStorage.getItem('currentPermissions'));
    // 登录页面放行
    if (to.path == '/login') {
        next();
        //权限为100的放行
    } else if (to.meta.authority.indexOf(100) != -1) {
        routerList.saveList();
        next();
        //符合权限的放行
    } else if (to.meta.authority.indexOf(Number(window.localStorage.getItem('currentPermissions'))) != -1) {
        routerList.saveList();
        next();
        //不合符权限的限制，并跳转回login页
    } else {
        Message({
            message: '无权限访问',
            type: 'error',
            duration: 3 * 1000
        })
        next('/login');
    }


})

//在跳转之后
router.afterEach(() => {

})
